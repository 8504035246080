// IMPORTS
@import (reference) "../settings.less";

// ALTERNATING BANNER
.alternating-banners-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    &:before { // in case borwser renders 1 pixel gap;
        .full-bg();
        background-color: @light-bg;
        height: 99%;
        bottom: 3px;
    }
}

.alternating-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:before {
        .full-bg();
        background: @sec-color;
    }

    &:has(nth-child(2)) {
        justify-content: space-between;
    }

    figure {
        width: 48.89%;
        aspect-ratio: ~"704/526";
        align-self: flex-start;

        img {
            .img-contain();
        }
    }

    .text-wrapper {
        flex: 1;
        flex-direction: column;
        padding: 0 0 0 10.83%;
        margin-block: 32px;

        &:only-child {
            width: 100%;
            max-width: 950px;
            padding: 0 !important;
            text-align: center;

            > a {
                margin: 0 auto;
            }
        }

        > b {
            .headline();
            display: block;
        }

        > div {
            padding-bottom: 32px;
        }

        > a {
            .confirm-button();
            height: auto;

            &:hover {
                border-color: @header-color;
            }
        }
    }

    &:nth-child(even) {

        .text-wrapper {
            order: -1;
            padding: 0 10.83% 0 0;
        }

        &:before {
            background: #FFEAB3;
        }
    }
}

.alternating-banners-wrapper + .promo-banners-wrapper { // gets rid of the gap if these 2 are next to eachother
    margin-top: calc(-1 * var(--rowGap));
}

// MAINPAGE PRODUCTS
.main-page-products-wrapper {
    width: 100%;
    position: relative;

    .headline {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }

    .slider-button1 {
        top: calc(50% - 88px);
    }
}

// ICON BANNER
.promo-banners-wrapper {
    .grid();
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    gap: 32px;
    padding: var(--rowGap) 0;
    margin-bottom: 108px;
    position: relative;

    &:before {
        .full-bg();
        background-color: #0061AF;
    }
}

.promo-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    line-height: 1.2;


    figure {
        width: 56px;
        aspect-ratio: 1;
        margin: 24px 0;
        position: relative;

        img {
            .img-contain();
            border-radius: 16px;
        }

        &:before {
            content: '';
            position: absolute;
            background: white;
            top: -24px;
            left: 50%;
            transform: translateX(-50%);
            width: 56px;
            height: 1px;
        }
    }

    b {
        font-size: 24px;
    }
}

// BLOG BANNER
.blog-banners-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .headline {
        margin-bottom: 32px;
    }
}

.blog-banners-wrapper {
    .grid();
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px;
}

.blog-banner {
    border-bottom: 1px dashed @sec-color;
    .grid();
    grid-template-rows: auto auto 1fr;

    > *:not(figure) {
        margin-left: 32px;
    }

    > figure {
        width: 100%;
        aspect-ratio: ~"704/362";
        margin-bottom: 32px;

        img {
            .img-crop();
            border-radius: 16px;
        }
    }

    > b {
        font-size: 18px;
        margin-bottom: 8px;
    }

    > div {
        margin-bottom: 24px;
    }

    > a {
        .confirm-button();
        height: auto;
        margin-bottom: 32px;
    }
}